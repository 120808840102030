import styled from "styled-components";

export const Wrap = styled.div`
  width: 80%;
  max-width: 1000px;
  margin: 20px auto 20px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 0 20px;
  justify-content: space-between;

  .logo {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 220px;
    transition: all 1s ease-out;
  }

  .filler {
    width: 40px;
  }

  .open {

    nav {
      width: 100%;
    }
    
    .icon {
      z-index: 10;
      height: 25px;
      width: 28px;
      border-bottom: rgba(255,255,255,0);
    }

    .icon:after{
      transform: translate(0px, 10px) rotate(45deg);
    }

    .icon:before{
      height: 0px;
      transform: translate(0px, -25px) rotate(-45deg);
    }
  }

  .openIMG{
    right: 20%;
    
    @media(max-width: 980px and min-width(691px)) {
      opacity: 0;
      right: -20px;
    }
  }
  
  @media(max-width: 690px) {
    width: 90%;
    margin-top: 20px;
    padding: 20px 0 35px;

    .logo {
      width: 180px;
      height: 60px;
    }

    .open {
      nav{
        width: 100vw;
        padding-top: 10px;
        height: calc(100vh - 95px);
      }
    }
    .openIMG {
      right: 50%;
    }
  }
`;

export const Menu = styled.div`
  position: relative;

  .icon {
    position: absolute;
    width: 25px;
    height: 10px;
    border-bottom: 2px solid #fff;
    transition: all 0.3s;
    cursor: pointer;

    &:after{
      content: '';
      position: absolute;
      top: 0px;
      height: 0px;
      left: 0px;
      width: 25px;
      border-bottom: 2px solid #fff;
      transition: all 0.3s;
      cursor: pointer;
    }

    &:before{
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0px;
      width: 25px;
      height: 10px;
      border-bottom: 2px solid #fff;
      transition: all 0.3s;
      cursor: pointer;
    }
  }

  .menu{
    margin-left: 40px;
    white-space: nowrap;
    width: 0;
    overflow-x: hidden;
    transition: all 1s ease-in-out;
  } 
  
    .menu ul{
    list-style: none;
    padding: 5px;
    letter-spacing: 1px;
    margin: 0px 5px;
    }
  
    .menu li{
      display: inline-block;
    }
  
    .menu a{
    text-align: -webkit-match-parent;
    text-decoration: none;
    padding: 5px;
    margin: 0 5px;
    font-family: "Josefin Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #f4f4f4;
    }

  @media(max-width: 690px) {
    .menu-mobile{
      position:absolute;
      flex-direction: column;
      top: 54px;
      left: -59px;
      z-index: 5;
      width: 100vw;
      height: 0px;
      background-color: #232323;
    }
    .menu-mobile li{
      display: flex;
      justify-content: center;
      padding: 30px 0px;
    }
    .menu-mobile a{
     font-size: 20px;
    }
  }
`;
