import styled from "styled-components";

export const Wrap = styled.div`
  max-width: 890px;
  width: 90vw;
  margin: auto;
  padding-top: 80px;

  @media (max-width: 620px) {
    padding-top: 30px;
  }
`;

export const Subtitle = styled.nav`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 100;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 0 auto 15px;
  border-bottom: 1px solid #666;
  color: #ddd;
  width: 160px;
  padding-right: 8px;
  cursor: pointer;

  img {
    margin-bottom: -6px;
  }

  a {
    color: #ccc;
    text-decoration: none;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  h1{
    color: #dfdfdf;
    margin: 0;
    font-size: 60px;
    line-height: 72px;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
  }

  a {
    max-height: 60px;
  }
  .arrowPort {
    width: 60px;
    cursor: pointer;
  }

  .next {
    transform: rotateZ(180deg);
  }

  @media (max-width: 920px) {
    h1{
      font-size: 50px;
    }
  }

  @media (max-width: 710px) {
    font-size: 40px;
    line-height: 38px;
  }
`;

export const Content = styled.div`
  display: flex;
  margin-top: 40px;
  h1{
      font-size: 50px;
    }
  .img div,
  .image,
  .img {
    width: 300px;
  }

  .img {
    img {
      cursor: pointer;
      clip-path: polygon(
        0 0,
        calc(100% - 40px) 0,
        100% 40px,
        100% 100%,
        40px 100%,
        0 calc(100% - 40px)
      );
      padding-left: 1px;
    }
  }

  .img button {
    background: rgba(0, 0, 0, 0);
    border: none;
  }

  p {
    text-align: justify;
    padding-left: 40px;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 300;
    line-height: 22px;
    margin: 0 0 16px;
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .img {
      margin: auto;
      img {
        /* max-width: 90vw; */
      }
    }

    .text {
      margin: 30px auto 0px;
    }

    p {
      padding-left: 0px;
    }
  }
`;

export const Ancor = styled.div`
  position: relative;
  margin: auto;
  width: 25px;
  margin-top: 50px;

  a {
    text-decoration: none;
    color: #f4f4f4;
  }

  span {
    font-family: "Josefin Sans", sans-serif;
    font-size: 12px;
  }

  img {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
