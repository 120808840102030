export async function GetLocation (cep) {
  const route = 'https://ws.apicep.com/cep.json?code=';

  let request = await fetch(route + cep, {
    method: 'get'
  }).then(response => response.json())
  
  delete request.ok
  delete request.status
  delete request.statusText
  delete request.code
  
  return request
}
