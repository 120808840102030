import React, { useState } from "react";
import { Wrap } from "./styles";
import N from "../../assets/effect/N.svg";

const AnimPage = () => {
  const [classe, setClasse] = useState("glowing");

  setTimeout(function () {
    if (classe === "glowing") {
      setClasse("slowhide");
    }
  }, 1000);

  return (
    <Wrap className={classe}>
        <div>
          <img src={N} alt="n" />
        </div>
    </Wrap>
  );
};

export default AnimPage;
