import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

class Component extends React.Component {
  state = { modalIsOpen: false, currentIndex: 0 };
  toggleModal = () => {
    this.setState((state) => ({ modalIsOpen: !state.modalIsOpen }));
  };
  onImageChange = (index) => {
    this.setState((state) => ({ currentIndex: index }));
  };

  
  render() {
    const { modalIsOpen } = this.state;
    const images = this.props.pictures.map(function(e) {
      return { source: e }
    });
    
    //função que estilisa o footer para contar as views e mostrar 1/3
    const CustomModalFooter = ({ currentIndex, views }) => {
      const activeView = currentIndex + 1;
      const totalViews = views.length;

      if (!activeView || !totalViews) return null;

      return (
        <span>
          {activeView} de {totalViews}
        </span>
      );
    };

    return (
      <>
        <button
          type="button"
          className="btn-fullScreen cover clippatch"
          onClick={this.toggleModal}
        >
          <Carousel
            onClick={this.onImageClick}
            trackProps={{ onViewChange: (index) => this.onImageChange(index) }}
            components={{ FooterCount: CustomModalFooter }}
            views={images}
            
          />
        </button>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={this.toggleModal}>
              <Carousel
                currentIndex={this.state.currentIndex}
                components={{ FooterCount: CustomModalFooter }}
                views={images}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </>
    );
  }
}
export default Component;