import React, { useState, useEffect } from "react";
import ScrollToTop from "../../components/scrolltop";
import Galleria from "./gallery";
import { Link, useParams, useLocation} from "react-router-dom";
import request from "./request.json";
import { Wrap, Title, Content, Subtitle } from "./styles";
import Ancor from "../../components/ancor";
import Services from "../../components/servicesDone";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ArrowPort from "../../assets/SetaPortfolio.svg";
import AnimPage from "../../components/animPage";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Project() {
  const [data, setData] = useState([]);
  const [route, setRoute] = useState([]);
  // const [partner, setPartner] = useState(false);
  let { slug } = useParams();
  let query = useQuery();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(function() {
      setLoading(false)
    }, 3200);
  }, [loading]);

  useEffect(() => {
    setData(request.data[slug]);

    if(query.get('partner')) {
      let temp = []
      if(request.data[slug].before_partner) {
        temp[0] = `${request.data[slug].before_partner}?partner=${query.get('partner')}`
      }
      if(request.data[slug].next_partner) {
        temp[1] = `${request.data[slug].next_partner}?partner=${query.get('partner')}`
      }
      setRoute(temp)
    } else {
      setRoute([request.data[slug].before, request.data[slug].next])
    }

  }, [data, slug]);

  return (
    <>
      <ScrollToTop/>
      {loading ? <AnimPage /> : <></>}
      <Header />
      <Wrap>
        <Subtitle className="">
          <Link to="/portfolio">
            <img src={ArrowPort} alt="voltar" />
            NOSSO PORTFÓLIO
          </Link>
        </Subtitle>
        <Title className="">
          { route[0] ? 
          <a onClick={() => {window.location.href=`/portfolio/${route[0]}`}}>
            <img className="arrowPort" src={ArrowPort} alt="voltar" />
          </a> : null }
          <h1>{data.title}</h1>
          { route[1] ?
          <a onClick={() => {window.location.href=`/portfolio/${route[1]}`}}>
            <img className="arrowPort next" src={ArrowPort} alt="voltar" />
          </a> : null }
        </Title>
        <Content className="alphaeffect">
          <div className="img">
            {data.galery ? <Galleria pictures={data.galery} /> : <></>}
          </div>
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </Content>
        {data.services ? <Services content={data.services} /> : <></>}
        <Ancor />
      </Wrap>
      <Footer />
    </>
  );
}
