import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  @media(max-width: 980px) {
    width: 90vw;
    margin: auto;
  }
`;

export const Unit = styled.div`
  position: relative;
  width: 30%;
  margin: 0 auto 40px;
  height: auto;

  @media(max-width: 980px) {
    width: 300px;
  }

  .cover {
    width: 100%;
  }

  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 90%;
    padding: 8px 0 10px;
    text-align: center;
    background-color: rgba(23,23,23, 0.85);
    font-family:"Josefin Sans", sans-serif;
    color: #ddd;
    font-weight: 600;
    font-size: 14px;

    span {
      display: block;
      margin-top: 8px;
      font-family: 'Josefin Sans', sans-serif;
      font-size: 16px;
      letter-spacing: 0.15px;
      font-weight: 300;
    }
  }
  .hvr-shutter-in-vertical:before {
    content: "";
    position: absolute;
    z-index: 8;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ddd;
    /* -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
    -webkit-transition-property: transform;
    transition-property: transform; */
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    /* -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; */
      p {
        text-align: justify;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .hvr-shutter-in-vertical:hover:before, .hvr-shutter-in-vertical:focus:before, .hvr-shutter-in-vertical:active:before {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    //position: absolute;
    }


  .hover {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0%;
    height: 0%;
    transform: translate(-50%, -50%);
    background: rgba(0,0,0, 0.9);
    opacity: 0;
    padding: 10px;
    text-align: center;
    padding-top: 20px;
    clip-path: polygon(0 0, calc(100% - 40px) 0, 100% 40px, 100% 100%, 40px 100%, 0 calc(100% - 40px));
    img {
      margin: auto;
    }

    h4 {
      font-family: 'Josefin Sans', sans-serif;
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 1px;
      margin: 0;
    }

    p {
      text-align: justify;
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    button {
        :focus {outline:none;}
      ::-moz-focus-inner {border:0;}
      background-color: #F4F4F4;
      padding: 8px 60px;
      border: none;
      text-transform: uppercase;
      color: rgba(0,0,0, 0.9);
      font-weight: 600;
      letter-spacing: .75px;
      cursor: pointer;
    }
  }
  &:hover{
    .hover{
      opacity: 1;
      width: 100%;
      height: 100%;
      p {
        opacity: 1;
      }
      h5 {
        opacity: 1;
      }
    }
  }
`;

export const Model = styled.div`
    position: relative;
    vertical-align: middle;
    max-width: 90vw;
    z-index: 10;
    width: 600px;
    min-height: 300px;
    box-sizing: border-box;
    padding: 40px 20px 100px;
    background-color: #7F7F7F;
    background-position:center;
    text-align: center;
    clip-path: polygon(0 0, calc(100% - 40px) 0, 100% 40px, 100% 100%, 40px 100%, 0 calc(100% - 40px));
    .icon {
      width: 100px;
    }

    h2 {
      font-family: 'Josefin Sans', sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.18px;
      color: #F4F4F4;
    }

    p {
      text-align: justify;
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.5px;
    }

    .back {
      position: absolute;
      bottom: 44px;
      right: 40px;
      cursor: pointer;
    }
`;


