import styled from "styled-components";

export const Wrap = styled.div`
  position: relative;
  margin: auto;
  width: 25px;
  margin-top: 50px;

  div {
    cursor: pointer;
    color: #f4f4f4;
  }

  span {
    font-family: "Josefin Sans", sans-serif;
    font-size: 12px;
  }

  img {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;