import styled from "styled-components";

export const Servicos = styled.div`
  max-width: 900px;
  width: 90vw;
  margin: 60px auto;
  box-sizing: border-box;
  
  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .unit {
      width: 350px;
      box-sizing: border-box;

      div{
        display: flex;
        align-items: center;
      }
    }

    p {
      font-family: "Josefin Sans", sans-serif;
      font-size: 14px;
      line-height: 24px;
      margin-left: 15px;
    }
  }

  @media(max-width: 900px) {
    .wrap {
      .unit {
        width: 45vw;
      }
    }
  }

  @media(max-width: 600px) {
    .wrap {
      justify-content: center;
      .unit {
        width: 300px;
      }
    }
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin: 0 auto 40px;
  font-family: "Josefin Sans", sans-serif;
`;
