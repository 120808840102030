import styled from "styled-components";

export const Wrap = styled.div`
  width: 100vw;
  

  .unit {
    position: relative;
    width: 100vw;
    img {
      width: 100%;
    }
  }

  .title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 400px;
    padding: 25px;
  
    h1 {
      font-family: 'Josefin Sans', 'sans-serif';
      font-size: 40px;
      line-height: 56px;
      font-weight: 300;
      margin: 0;
      padding: 0;
    }
  }
  .pleft{
    left: 10%;
  }
  .pright{
    right: 10%;
  }
  .h1{
    position:absolute;
    top: 50%;
    right: 3.5%;
    font-family: 'Josefin Sans', 'sans-serif';
    font-size: 30px;
    line-height: 50px;
    font-weight: 300;
    margin: 0px;
    padding: 0px;
  }
  .control-dots .selected {
    border: solid 2px #fff;
    padding: 3px;
    background-color: rgba(255,255,255,0);
  }

  @media(min-width: 901px) {
    .unit {
      background-size: cover;
      background-position: center center;
    }
    
  }

  @media(max-width: 900px) {
    .pleft{
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .pright{
      right: 50%;
      transform: translate(-50%, -50%);
    }
    .unit {
      background-size: cover;
      background-position: center center;
    }
    .title {
      position: absolute;
      top: 50%;
      width: 280px;
      padding: 15px;
      
      h1 {
        font-family: 'Josefin Sans', sans-serif;
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        margin: 0;
        padding: 0;
      }
    }
  }
`;