import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./pages/home";
import Company from "./pages/company";
import Services from "./pages/services";
import Portfolio from "./pages/portfolio";
import Project from "./pages/project";
import Contact from "./pages/contact";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/empresa" component={Company} />
      <Route exact path="/servicos" component={Services} />
      <Route exact path="/portfolio" component={Portfolio} />
      <Route exact path="/portfolio/:slug" component={Project} />
      <Route exact path="/contato" component={Contact} />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;