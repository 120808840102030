import React, {useState, useEffect} from "react";
import AnimPage from "../../components/animPage";
import Modal from 'react-modal';
import { Wrap, Title, Subtitle } from "../../style/styles";
import { Content, Unit, Model } from "./styles";
import Header from "../../components/header";
import Footer from "../../components/footer";
import textura from "../../assets/texture.svg";
import Ancor from "../../components/ancor";

//import dos icones
import Architecture from "../../assets/ic_architecture.svg";
import Building from "../../assets/services-icons/building.svg";
import Plumber from "../../assets/services-icons/plumber.svg";
import Extinguisher from "../../assets/services-icons/extinguisher.svg";
import Eletrical_services from "../../assets/services-icons/electrical-services.svg";
import Finance from "../../assets/services-icons/finance.svg";
import Bx_area from "../../assets/services-icons/bx-area.svg";
import Real_state_agent from "../../assets/services-icons/real-estate-agent.svg";
import Solid_building from "../../assets/services-icons/solid_building.svg";
import Arrow from "../../assets/arrow.svg";

//imports para  imagem da pag serviços.
import Arquitetonico from "../../assets/servicos/WEBP/Servico-Integrare-Projeto-Arquitetonico.webp";
import Estrutura from "../../assets/servicos/WEBP/Servico-Integrare-Projeto-Estrutural.webp";
import Hidrossanitario from "../../assets/servicos/WEBP/Servico-Integrare-Projeto-Hidrossanitario.webp";
import Incêncidio from "../../assets/servicos/WEBP/Servico-Integrare-Projeto-Preventivo-de-Incendio.webp";
import Eletrico from "../../assets/servicos/WEBP/Servico-Integrare-Projeto-Eletrico-Telecomunicacoes.webp";
import Financeiro from "../../assets/servicos/WEBP/Servico-Integrare-Projeto-Viabilidade-Financeira.webp";
import Terrenos from "../../assets/servicos/WEBP/Servico-Integrare-Projeto-Regularizacao-de-Terrenos.webp";
import Imobiliaria from "../../assets/servicos/WEBP/Servico-Integrare-Projeto-Incorporacao-Imobiliaria.webp";
import Condominio from "../../assets/servicos/WEBP/Servico-Integrare-Projeto-Instituicao-de-Condominio.webp";


const customStyles = {
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    backgroundColor:'rgba(0,0,0,0)',
    border: 'none',
  },
};

const Services = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const [img, setImg] = useState('');
  const [loading, setLoading] = useState(true);
  // const [status, setStatus] = useState('');
  // let subtitle;

  useEffect(() => {
    setTimeout(function() {
      setLoading(false)
    }, 3200);
  }, [loading]);

  function openModal(id) {
    setIsOpen(true);
    switch (id) {
      case 0:
        setImg(Architecture)
        setText(`
          <h2>PROJETO ARQUITETÔNICO</h2>
          <p>
            É a materialização de todas as ideias provenientes do “brainstorm” realizado entre a empresa e o cliente. Na sua fase de pré-projeto, podemos estudar e viabilizar técnica e economicamente, a melhor maneira de atender as necessidades do cliente e todos os problemas envolvidos neste processo.
         </p> 
          <p>
           A responsabilidade do projeto arquitetônico é o desenvolvimento da integração de todos os projetos complementares (estrutural, hidrossanitário, preventivo e elétrico) oferecendo totais condições de exequibilidade.
          </p>`);
        break;
      case 1:
        setImg(Building)
        setText(`
          <img className="icon" src={Building} alt="" />
          <h2>PROJETO ESTRUTURAL</h2>
          <p>
            Com o projeto arquitetônico pronto, é necessária a criação de um projeto estrutural, que consiste em dimensionar e detalhar os elementos estruturais (fundação, pilares, vigas e lajes). Assim conseguimos garantir uma obra segura, econômica e durável.
          </p> 
          <p>
            Ele é muito importante para evitar elementos em espaços inapropriados, gerando estruturas superdimensionadas, causando desperdícios de recursos, ou o oposto, estruturas subdimensionadas, que podem gerar patologias futuras e arriscadas.
          </p>`);
        break;
      case 2:
        setImg(Plumber)
          setText(`
            <h2>PROJETO HIDROSSANITÁRIO<h2>
            <p>
              O projeto hidrossanitário consiste na elaboração do sistema hidráulico (a distribuição de água na edificação), do sanitário (para onde a água utilizada vai) e de águas pluviais (reutilização da chuva em reservatórios).
            </p>
            <p>
              É imprescindível que este projeto esteja compatibilizado com o projeto arquitetônico e o estrutural, pois são eles que definem onde serão projetados os sistemas, e assim, evita-se retrabalhos.
            </p>`)
      break;
      case 3:
        setImg(Extinguisher)
        setText(`
          <h2>PROJETO PREVENTIVO DE INCÊNDIO</h2>
          <p>
            O projeto preventivo de incêndio é uma exigência que promove a implantação correta dos equipamentos para garantir ao máximo, a segurança das pessoas que utilizam os edifícios.
          </p>
          <p>
            Deve contemplar alguns parâmetros e no seu projeto é necessário indicar as características técnicas dos equipamentos contra incêndio, tais como as instruções para instalação, necessidade e demanda de água.
          </p>`)
      break;
      case 4:
        setImg(Eletrical_services)
        setText(`
          <h2>PROJETO ELÉTRICO/TELECOMUNICAÇÕES</h2>
          <p>
            O projeto elétrico e telecomunicações se faz através da definição dos materiais e planejamento das instalações que demandam energia elétrica na etapa final da obra, sendo tanto para iluminação ou rede de tomadas. 
          </p>
          <p>
            Idealiza o funcionamento apropriado das instalações elétricas, estabelecendo os critérios, normas e instalação dos equipamentos, visando maior segurança para o usuário.
          </p>
        `)
      break;
      case 5:
        setImg(Finance)
        setText(`
          <h2>VIABILIDADE FINANCEIRA</h2>
          <p>
            Calculamos a viabilidade financeira em cima das despesas e lucros potenciais de um projeto. Ela permite verificar se o investimento necessário para colocar em prática uma obra é viável para seus investidores.
          </p>
          <p>
            Nesta etapa, mostramos dados reais sobre o provável retorno financeiro de acordo com a injeção de dinheiro e a análise de mercado, resultando em um parecer que determina os próximos passos.
          </p>
        `)
      break;
      case 6:
        setImg(Bx_area)
        setText(`
          <h2>REGULARIZAÇÃO DE TERRENOS</h2>
          <p>
            Na regularização de terrenos, é realizada uma verificação na regularidade dos documentos atuais após a compra, ou a inexistência da documentação. Em ambos os casos, estudamos o terreno, os documentos disponíveis e as legislações pertinentes do local.</p>
          <p>
             O resultado disso é um esboço inicial do empreendimento a ser construído, contemplando as necessidades e funcionalidades, bem como as condições locais, documento a ser apresentado junto com os outros projetos na Secretaria Municipal de Urbanismo para regularização.
          </p>
        `)
      break;
      case 7:
        setImg(Real_state_agent)
        setText(`
          <h2>INCORPORAÇÃO IMOBILIÁRIA</h2>
          <p>
            O procedimento que viabiliza a construção de empreendimentos, como condomínios residenciais, em um terreno delimitado é chamado de incorporação imobiliária.</p>
          <p>
            Ela permite a venda das habitações que serão construídas por meio do processo de alienação, na qual existe a captação de recursos para financiar o projeto e quitá-lo com a negociação das unidades residenciais. 
          </p>
        `)
      break;
      case 8:
        setImg(Solid_building)
        setText(`
          <h2>INSTITUIÇÃO DE CONDOMÍNIO</h2>
          <p>
            Para instituir o condomínio, é preciso realizar o ato esclarecendo a individualização de cada unidade independente (habitações). Nesse processo é necessário identificar o tipo de unidade, indicação cadastral (número da unidade), pavimento, se é área de uso exclusivo ou área de uso comum.
          </p>
        `)
      break;
      
      default:
          console.log(`ERROR.`);
    }
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      {loading ? <AnimPage /> : <></>}
      <Header />
      <Wrap>
        <Title>NOSSOS SERVIÇOS</Title>
        <Subtitle>
        Priorizamos os processos e a organização
        </Subtitle>
        <Content className="alphaeffect">
          <Unit>
            
            <img className="cover clippatch" src={Arquitetonico} alt="Projeto Arquitetônico" />
            <h3>Projeto Arquitetônico</h3>
            <div className="hover hvr-shutter-in-vertical">
              <img className="icon" src={Architecture} alt="Projeto Arquitetônico" />
              <h4>Projeto Arquitetônico</h4>
              <p>
                É a materialização de todas as ideias provenientes do “brainstorm”...
              </p>
              <button onClick={() => openModal(0)}>Ler Mais</button>
            </div>
          </Unit>
          <Unit>
            <img className="cover clippatch" src={Estrutura} alt="Projeto Estrutural" />
            <h3>Projeto Estrutural</h3>
            <div className="hover hvr-shutter-in-vertical">
              <img className="icon" src={Building} alt="Projeto Estrutural" />
              <h4>Projeto Estrutural</h4>
              <p>
                Com o projeto arquitetônico pronto, é necessária a criação de um projeto...
              </p>
              <button onClick={() => openModal(1)}>Ler Mais</button>
            </div>
          </Unit>
          <Unit>
            <img className="cover clippatch" src={Hidrossanitario} alt="Projeto Hidrossanitário" />
            <h3>Projeto Hidrossanitário</h3>
            <div className="hover hvr-shutter-in-vertical">
              <img className="icon" src={Plumber} alt="Projeto Hidrossanitário" />
              <h4>Projeto Hidrossanitário</h4>
              <p>
              O projeto hidrossanitário consiste na elaboração do sistema hidráulico...
              </p>
              <button onClick={()=>openModal(2)}>Ler Mais</button>
            </div>
          </Unit>
          <Unit>
            <img className="cover clippatch" src={Incêncidio} alt="Projeto Preventivo De Incêndio" />
            <h3>Projeto Preventivo De Incêndio</h3>
            <div className="hover hvr-shutter-in-vertical">
              <img className="icon" src={Extinguisher} alt="Projeto Preventivo De Incêndio" />
              <h4>Projeto Preventivo De Incêndio</h4>
              <p>
                O projeto preventivo de incêndio é uma exigência que promove...
              </p>
              <button onClick={()=>openModal(3)}>Ler Mais</button>
            </div>
          </Unit>
          <Unit>
            <img className="cover clippatch" src={Eletrico} alt="Projeto Elétrico/Telecomunicações" />
            <h3>Projeto Elétrico/Telecomunicações</h3>
            <div className="hover hvr-shutter-in-vertical">
              <img className="icon" src={Eletrical_services} alt="Projeto Elétrico/Telecomunicações" />
              <h4>Projeto Elétrico/Telecomunicações</h4>
              <p>
                O projeto elétrico e telecomunicações se faz através da definição...
              </p>
              <button onClick={()=>openModal(4)}>Ler Mais</button>
            </div>
          </Unit>
          <Unit>
            <img className="cover clippatch" src={Financeiro} alt="Viabilidade Financeira" />
            <h3>Viabilidade Financeira</h3>
            <div className="hover hvr-shutter-in-vertical">
              <img className="icon" src={Finance} alt="Viabilidade Financeira" />
              <h4>Viabilidade Financeira</h4>
              <p>
                Calculamos a viabilidade financeira em cima das despesas e lucros...
              </p>
              <button onClick={()=>openModal(5)}>Ler Mais</button>
            </div>
          </Unit>
          <Unit>
            <img className="cover clippatch" src={Terrenos} alt="Regularização De Terrenos" />
            <h3>Regularização De Terrenos</h3>
            <div className="hover hvr-shutter-in-vertical">
              <img className="icon" src={Bx_area} alt="Regularização De Terrenos" />
              <h4>Regularização De Terrenos</h4>
              <p>
              Na regularização de terrenos, é realizada uma verificação...
              </p>
              <button onClick={()=>openModal(6)}>Ler Mais</button>
            </div>
          </Unit>
          <Unit>
            <img className="cover clippatch" src={Imobiliaria} alt="Incorporação Imobiliária" />
            <h3>Incorporação Imobiliária</h3>
            <div className="hover hvr-shutter-in-vertical">
              <img className="icon" src={Real_state_agent} alt="Incorporação Imobiliária" />
              <h4>Incorporação Imobiliária</h4>
              <p>
              O procedimento que viabiliza a construção de empreendimentos...
              </p>
              <button onClick={()=>openModal(7)}>Ler Mais</button>
            </div>
          </Unit>
          <Unit>
            <img className="cover clippatch" src={Condominio} alt="Instituição De Condomínio" />
            <h3>Instituição De Condomínio</h3>
            <div className="hover hvr-shutter-in-vertical">
              <img className="icon" src={Solid_building} alt="Instituição De Condomínio" />
              <h4>Instituição De Condomínio</h4>
              <p>
              Para instituir o condomínio, é preciso realizar o ato esclarecendo...
              </p>
              <button onClick={()=>openModal(8)}>Ler Mais</button>
            </div>
          </Unit>
        </Content>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
           >
            <Model style={{backgroundImage:`url(${textura})`}}>
              <img className="icon" src={img} alt="icon" />
              <div dangerouslySetInnerHTML={{ __html: text }}></div>
              
              <img className="back" src={Arrow} alt="voltar" title="voltar"  onClick={closeModal}/>
            </Model>
          </Modal>
        <Ancor />
      </Wrap>
      <Footer />
    </>
  );
};

export default Services;
