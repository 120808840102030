import React from "react";
import { Wrap } from "./styles";
import Logo from "../../assets/LogoFooter.svg";
import Facebook from "../../assets/mdi_facebook.svg";
import Instagram from "../../assets/mdi_instagram.svg";
import Linkedin from "../../assets/mdi_linkedin.svg";
import Map from "../../assets/mdi_map-marker.svg";
import Whatsapp from "../../assets/mdi_whatsapp.svg";
import Spotify from "../../assets/icon-spotify.svg";
import Youtube from "../../assets/icon-youtube.svg";
import Phone from "../../assets/mdi_phone.svg";


const Footer = () => {
  return (
    <Wrap>
      <img className="logo" src={Logo} title="Nós Resolvemos" alt="Nós Resolvemos" />
      <div className="info">
        <div>
          <img className="icon" src={Map} title="Map" alt="Map"  />
          <span>
            Av. Almirante Fonseca Neves, nº 369, Balneário Perequê, Porto
            Belo/SC
          </span>
        </div>
        <div>
          <img className="icon" src={Phone} title="Phone" alt="Phone" />
          <span>+55 47 99284 3569</span>
        </div>
      </div>
      <div className="icons">
        <div>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/integrareengenharia/"
          >
            <img className="icon" src={Instagram} alt="Instagram" title="Instagram" />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://facebook.com/integrareengenharia"
          >
            <img className="icon" src={Facebook} alt="Facebook" title="Facebook" />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/company/integrareengenharia/"
          >
            <img className="icon" src={Linkedin} alt="Linkedin" title="Linkedin" />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.youtube.com/channel/UC7qEKWpFDMnVhjMLaAzPuUw"
          >
            <img className="icon" src={Youtube} alt="Youtube" title="Youtube" />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://open.spotify.com/user/6pnbdl46rl7jwhjp8rn60t9sz"
          >
            <img className="icon" src={Spotify} alt="Spotify" title="Spotify" />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://web.whatsapp.com/send?phone=554792843569"
          >
            <img className="icon" src={Whatsapp} alt="Whatsapp" title="Whatsapp" />
          </a>
        </div>
        <div>
          <span>
            Desenvolvido por{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.2bchosen.com/"
            >
              <span>2BChosen Agency</span>
            </a>
          </span>
        </div>
      </div>
    </Wrap>
  );
};

export default Footer;
