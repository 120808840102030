import styled from "styled-components";

export const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: slowShow;
  animation-duration: 3.2s;

  div {
    position: relative;
    padding: 20px;
    border-radius: 100px;

    &:after {
      content: "";
      position: absolute;
      top: 0px;
      left: -300px;
      width: 400px;
      height: 100px;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 1)
      );
      animation-name: loading;
      animation-duration: 2s;
    }
  }

  img {
    margin: 0;
    height: 70px;
    transition: all 0.3s;
  }

  @keyframes loading {
    0% {
      left: -300px;
    }
    100% {
      left: 100px;
    }
  }

  @keyframes slowShow {
    0% {opacity: 1;}
    50% {opacity: 1;}
    100% {opacity: 0;}
  }
  @media only screen and (max-width: 600px) {
    img {
      margin-right: 10px;
      height: 23px;
    }
  }
`;
