import styled from "styled-components";

export const Content = styled.div`
  margin: auto;
  opacity: 0;
  p {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    margin-bottom: 20px;
    color: #d9d9d9;
  }

  .about {
    display: flex;

    .text {
      margin-top: -16px;
      flex-grow: 7;
      padding-right: 30px;
      padding-bottom: 16px;
    }
    .text {
      flex-grow: 4;
    }
  }

  .tags {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 80px;

    div {
      max-width: 300px;
      text-align: center;

      h2 {
        margin-top: 8px;    
        font-family: "Josefin Sans", sans-serif;
        font-weight: 300;
        font-size: 34px;
        line-height: 36px;
      }
    }
  }

  .owner {
    display: flex;
    margin-bottom: 70px;
    
    .right {
      margin-left: 40px;
    }

    .left {
      margin-right: 40px;
    }

    h2{
      font-size: 24px;
      text-transform: uppercase;
      font-family: "Josefin Sans", sans-serif;
      font-weight: 300;
      margin: 0 0 8px;
    }

    span {
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 400;
      size: 12px;
      line-height: 20px;
      color: #7F7F7F;
    }
    img{
      max-height: 410px;
    }
  }

  .employees {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .unit {
      text-align: center;
      width: 300px;
      margin-bottom: 50px;
      p {
        text-align: center;
        margin: 10px 0 5px;
        font-family: "Josefin Sans", sans-serif;
        font-size: 14px;
        font-weight: 300;
      }
      span {
        display: block;
        font-family: 'Josefin Sans', sans-serif;
        color: #7F7F7F;
        font-size: 12px;
        line-height: 16px;
      }
    }
    img {
      width: 100%;
    }
  }

  @media(max-width: 980px) {
    .about {
      flex-direction: column;

      .img {
        order: -1;
        margin: auto;

        img {
          max-width: 90vw;
        }
      }
      .text {
        margin: auto;
        margin-top: 15px;
        padding: 0;
        width: 90vw;
      }
    }

    .tags {
      flex-direction: column;
      margin-bottom: 40px;

      div {
        margin: auto;
      }
    }

    .owner {
      flex-direction: column;

      img {
        width: 90vw;
        max-width: 420px;
        margin: 0 auto 30px;
      }

      .left {
        order: 2;
      }

      .right, .left{
        width: 90vw;
        margin: auto;
      }
    }

    .employees {
      width: 90vw;
      margin: 0 auto 40px; 

      .unit {
        width: 150px;
        padding: 0 5px;
        margin: 15px auto;
        align-items: flex-start;
      }
    }
  }
`;

// @media(max-width: 620px) {
