import styled from "styled-components";

export const Title = styled.h1`
  font-size: 54px;
  text-align: center;
  line-height: 72px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 100;
  margin: auto;

  @media(max-width: 690px) {
    font-size: 36px;
  }

  @media(max-width: 420px) {
    font-size: 28px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 15px;
  text-align: center;
  line-height: 24px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 500;
  margin: 5px auto 50px;
  padding-top: 20px;
  width: 375px;
  color: #e0e0e0;
  border-top: 1px solid #7F7F7F;

  @media(max-width: 980px) {
    max-width: 80vw;
  }
`;

export const Wrap = styled.div`
  max-width: 1000px;
  margin: auto;
  padding-top: 80px;

  @media(max-width: 620px) {
    padding-top: 30px;
  }
`;

export const Submenu = styled.div`
  margin: auto;
  max-width: 520px;
  width: 90%;
  display: flex;
  justify-content: space-between;

  .unit {
    display: inline-block;
    margin-top: 24px;
    cursor: pointer;
    transition: all 0.3s;
    div {
      text-align: justify;
      padding: 10px 16px 8px;
    }
    span {
      margin: 0;
      transition: all 0.3s;
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 400;
      font-size: 13px;
      text-align: justify;
      text-transform:uppercase;
      color: #d9d9d9;
      padding-bottom: 3px;
      border-bottom: solid 1px #7F7F7F;
    }
    margin-bottom: 48px;
  }
  .unit:hover {
    background-color: #fff;
    span{
      color: #000;
      border: none;
    }
  }
  .active {
    background-color: #fff;
    span{
      color: #000;
      border: none;
    }
  }
`;
