import React, { useState, useEffect } from "react";
import ScrollToTop from "../../components/scrolltop";
import Banner from "../../components/banner";
import Header from "../../components/header";
import Footer from "../../components/footer";
import AnimHome from "../../components/animHome";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(function() {
      setLoading(false)
    }, 5000);
  }, [loading]);

  return (
    <>
      <ScrollToTop/>
      {loading ? <AnimHome /> : <></>}
      {/* <AnimHome /> */}
      <Header />
      <Banner />
      <Footer />
    </>
  );
}

export default Home