import styled from "styled-components";

export const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: slowShow;
  animation-duration: 5s;

  h1{
    font-size: 58px;
    letter-spacing: 1px;
  }
  div {
    display: inline-block;
    overflow: hidden;
    height: 68px;
    display: inline-flex;
  }
  img{
    /* margin-right: 10px; */
    height: 60px;
  }
  .glowing{
    .n{
      animation-name: glow;
      animation-duration: 2s;
    }
  }
  .after{
    transition: all 1s;
    width: 0px;
  }
  .before{
    transition: all 0.7s;
    width: 0px;
  }
  .active {
    .before{
      width: 60px;
    }
    .after{
      margin-left: 20px;
      width: 750px;
      justify-content: space-around;
    }
  }

  @keyframes glow {
    0% {opacity: 1;}
    25% {opacity: 0.3;}
    50% {opacity: 1;}
    75% {opacity: 0.3;}
    100% {opacity: 1;}
  }

  @keyframes slowShow {
    0% {opacity: 1;}
    75% {opacity: 1;}
    100% {opacity: 0;}
  }

  @media only screen and (max-width: 600px) {
    img{
      margin-right: 10px;
      height: 23px;
    }
    .active {
      .before{
        width: 20px;
      }
      .after{
        margin-left: 0px;
        width: 250px;
      }
    }
  }
`;
