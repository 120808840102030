import styled from "styled-components";


export const Formstyle = styled.form`
  margin: auto;
  width: 650px;

  div {
    display: flex;
    margin-bottom: 20px;
    input, textarea, select{
      
      width: 100%;
      box-sizing: border-box;
      padding: 15px;
      background-color: rgba(	127, 127, 127, 0.7);
      color: #eee;
      border-radius: 4px 4px 0 0;
      font-size: 15px;
      line-height: 24px;
      border:none;

      ::placeholder {
        opacity: 1;
        color: #eee;
      }
    }
    textarea {
      font-family:'Arial';
      height: 140px;
    }
   
    button {
      width: 300px;
      margin: auto;
      height: 46px;
      padding: 15px 0;
      font-family:"Josefin Sans", sans-serif;
      color: #f7f7f7;
      letter-spacing: 0.75px;
      background: rgba(0,0,0,0);
      border: solid 1px #f7f7f7;
      cursor: pointer;
      transition: all 0.3s;
      
      
      &:hover{
        background-color: #fff;
        color: #000;
      }  
    }

  }
  .double {
    input:first-child {
        margin-right: 10px;
    }
    select:first-child{
      margin-right: 10px;
    }
    input:nth-child(2) {
        margin-left: 10px;
    }
    select:nth-child(2){
      margin-left: 10px;
    }
  }

  .file {
    width: 300px;
    box-sizing: border-box;

    img {
      margin-right: 10px;
      height: 22px;
     
    }

    label {
      width: 300px;
      cursor: pointer;  
      display: flex;
      align-items: center;
      font-family:     "Josefin Sans", sans-serif;
      font-size: 13px;
      padding: 11px 49px;
      border: solid 1px #fff;
      justify-content: center;

    }

    input {
      display: none;
    }
  }
  .flex-wrap{
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    div {
      padding: 0;
      margin-bottom: 0;
    }
    button {
      margin: 0;
    }
  }

  @media(max-width: 690px) {
    width: 90%;
    div {
      margin-bottom: 15px;
      flex-direction: column;
      input, textarea {
        padding: 10px;
      }
    }
    .double {
      input:first-child, select:first-child {
        margin-right: 0px;
        margin-bottom: 15px;
      }
      input:nth-child(2), select:nth-child(2) {
        margin-left: 0px;
      }
    }
    .flex-wrap{
      .file {
        margin-bottom: 30px;
        label {
          box-sizing: border-box;
          
        }
      }
    }
  }
`;

export const Maps = styled.div`
  margin: 100px 0 60px;
  
  h2 {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 44px;
    line-height: 56px;
    text-align: center;
    font-weight: 100;
  }
  

  iframe {
    width: 100%;
    height: 400px;
  }

  @media(max-width: 690px) {
    margin: 50px 0 30px;

    h2 {
      font-size: 26px;
    }

    iframe {
      height: 250px;
    }
  }
`;
