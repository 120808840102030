import React, { useState, useEffect } from "react";
import ScrollToTop from "../../components/scrolltop";
import AnimPage from "../../components/animPage";
import { Slide, Fade } from "@material-ui/core";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Values from "../../assets/diamond.svg";
import Mission from "../../assets/mission.svg";
import Vision from "../../assets/binoculars.svg";
import Image from "../../assets/Empresa/Manifesto-Integrare.webp";
import Carlos from "../../assets/socios/Carlos-Pio-Junior-Integrare.webp";
import Raphael from "../../assets/socios/Raphael-Fortunato-Dal-Bello-Integrare.webp";
import Charlene from "../../assets/colabs/Colaboradora-Integrare-Charlene.webp";
import Elaine from "../../assets/colabs/Colaboradora-Integrare-Elaine.webp";
import Everlice from "../../assets/colabs/Colaboradora-Integrare-Everlice.webp";
import Jaqueline from "../../assets/colabs/Colaboradora-Integrare-Jaqueline.webp";
import Jordao from "../../assets/colabs/Colaborador-Integrare-Jordao.webp";
import Leandro from "../../assets/colabs/Colaborador-Integrare-Leandro.webp";
import Veronica from "../../assets/colabs/Colaboradora-Integrare-Veronica.webp";
import Vinicius from "../../assets/colabs/Colaborador-Integrare-Vinicius.webp";
import Yasmin from "../../assets/colabs/Colaboradores-Integrare-Yasmin.webp";
import Gustavo from "../../assets/colabs/Colaboradores-Integrare-Gustavo.webp";
import Andressa from "../../assets/colabs/Colaboradores-Integrare-Andressa.webp";
import { Wrap, Title, Submenu } from "../../style/styles";
import { Content } from "./styles";
import Ancor from "../../components/ancor";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";

const rapha = {
  name: "Raphael Fortunato Dal Bello",
  cargo: "Diretor Comercial da Integrare E. & A.",
  about:
    "<p>Engenheiro Civil graduado pela Universidade Univali (Itajaí – SC), é sócio e cofundador da Integrare E. & A., responsável por conduzir toda a engrenagem do departamento comercial da empresa, realizando desde a prospecção de novos clientes até o follow up dos projetos periodicamente com os mesmos.</p><p>Com mais de 8 anos de experiência na área da Construção Civil, Raphael é especialista em viabilidade financeira e econômica e desenvolvimento de projetos, possui uma visão macro que aliada com seu know how permite que os projetos desenvolvidos pela empresa sejam o mais rentáveis possíveis, extraindo o máximo potencial da área a ser construída.</p><p>Junto ao construtor realiza o levantamento e definição dos principais indicadores para a tomada de decisão como ROI e Margem Líquida.</p>",
  img: Raphael,
};

const nuno = {
  name: "Carlos Alberto Pio Junior ",
  cargo: "Diretor de Projetos da Integrare E. & A.",
  about:
    "<p>Engenheiro Civil graduado pela Universidade Univali (Itajaí – SC), é sócio e cofundador da Integrare E. & A., responsável por gerenciar o departamento de projetos e administrativo da empresa em conjunto com toda equipe de operações e de gestão administrativa.</p><p>Com mais de 10 anos de experiência na área da Construção Civil, Carlos é especialista em projetos arquitetônicos e execução de obras, sua forma de trabalho é reconhecida pela atenção aos detalhes, esmero e sofisticação, que aliado com seu conhecimento em integração permite que o os projetos realizados ocorram da forma mais compatível possível, buscando continuamente atender o budget dos empreendimentos.</p>",
  img: Carlos,
};

const Company = () => {
  const [submenuClass, setSubmenuClass] = useState([
    "unit active",
    "unit",
    "unit",
  ]);
  const [fading, setFading] = useState(["opacity alphaeffect", "hide", "hide"]);
  const [section, setSection] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setLoading(false);
    }, 3200);
  }, [loading]);

  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

  const handleSubmenuClick = (index, value) => {
    let items = [...submenuClass];
    for (let i = 0; i < items.length; ++i) {
      items[i] = "unit";
    }
    items[index] = "unit active";
    setSubmenuClass(items);
    setSection(value);
    handleFading(index);
  };

  const handleFading = (index) => {
    let items = [...fading];
    for (let i = 0; i < items.length; ++i) {
      items[i] = "hide";
    }
    setFading(items);
    applyShow(index);
  };

  const applyShow = (index) => {
    let items = [...fading];
    setTimeout(function test() {
      items[index] = "opacity";
      setFading(items);
    }, 100);
  };

  const TheCompany = () => {
    return (
      <Content className={`content ${fading[0]}`}>
        <div className="about">
          <Fade in="true" timeout={10000}>
            <Slide direction="right" in="true" timeout={4000}>
              <div className="text">
                <p>
                  "Nós resolvemos". É com essa filosofia que nós, da Integrare
                  Engenharia & Arquitetura, atuamos desde 2017 trazendo soluções
                  inovadoras e ágeis ao mercado da construção civil de Porto
                  Belo e região.
                </p>
                <p>
                  Buscamos a integração dos projetos necessários para o
                  desenvolvimento de empreendimentos de forma dinâmica, técnica
                  e arrojada, materializando o conceito do máximo aproveitamento
                  da obra em projetos que se destacam pela viabilidade
                  financeira e econômica.
                </p>
                <p>
                  Devido à constante busca por inovação em todos os âmbitos,
                  nosso portfólio cresce de forma exponencial. Aliando a isso
                  pesquisas de tendências mercadológicas e profundo conhecimento
                  do plano diretor das cidades onde atuamos, trazemos um grande
                  know how e velocidade.
                </p>
                <p>
                  Nossa equipe multidisciplinar de alta qualidade trabalha todos
                  os dias para levar ao construtor segurança, qualidade,
                  cumprimento de prazos e maiores rendimentos. Isso é o que nos
                  move.
                </p>
                <p>
                  Os resultados apresentados nos permitem criar uma relação de
                  confiança a longo prazo com nossos clientes e colaboradores.
                  Para nós, mais importante que aumentar nosso portfólio de
                  clientes, é atender e superar suas expectativas. Trazendo
                  sempre o equilíbrio da Tríplice de restrição: tempo, custo e
                  qualidade.
                </p>
              </div>
            </Slide>
          </Fade>
          <Fade in="true" timeout={10000}>
            <Slide in="true" direction="left" timeout={4000}>
              <div className="img">
                <a
                  href="https://www.youtube.com/watch?v=QN3FlFZz3pg"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    className="clippatch"
                    src={Image}
                    alt="Vista do alto de um empreendimento para o mar na cidade de Porto Belo"
                  />
                </a>
              </div>
            </Slide>
          </Fade>
        </div>
        <div className="tags">
          <Fade in="true" timeout={4000}>
            <Slide in="true" direction="right" timeout={4000}>
              <div>
                <img src={Mission} title="missao" alt="missao" />
                <h2>MISSÃO</h2>
                <p>
                  Oferecer serviços de Engenharia e Arquitetura com foco na
                  integração de projetos multidisciplinares, apresentando as
                  soluções mais rentáveis, eficientes e seguras, através de uma
                  estrutura completa de alta performance.
                </p>
              </div>
            </Slide>
          </Fade>
          <Fade in="true" timeout={4000}>
            <Slide in="true" direction="up" timeout={4000}>
              <div>
                <img src={Vision} title="vision" alt="vision" />
                <h2>VISÃO</h2>
                <p>
                Ser reconhecida como a melhor empresa de prestação de serviços de Engenharia e Arquitetura do Mercado. Tornar-se modelo em confiabilidade, inovação e qualidade.
                </p>
              </div>
            </Slide>
          </Fade>
          <Fade in="true" timeout={4000}>
            <Slide in="true" direction="left" timeout={4000}>
              <div>
                <img src={Values} title="valores" alt="valores" />
                <h2>VALORES</h2>
                <p>
                  A Integrare tem um permanente compromisso com: ética,
                  competência, responsabilidade, visão sistêmica, atenção aos
                  detalhes, responsabilidade sócioambiental, melhoria contínua,
                  desenvolvimento pessoal e profissional dos colaboradores.
                </p>
              </div>
            </Slide>
          </Fade>
        </div>
      </Content>
    );
  };

  const Owners = (first, second) => {
    return (
      <Content className={fading[1]}>
        <div className="owner">
          <Slide in="true" direction="right" timeout={4000}>
            <img
              className="clippatch"
              src={first.img}
              alt="Sócio e Diretor"
              title="Sócio e Diretor"
            />
          </Slide>
          <Slide in="true" direction="left" timeout={4000}>
            <div className="right">
              <div className="upper">
                <h2>{first.name}</h2>
                <span>{first.cargo}</span>
              </div>
              <div dangerouslySetInnerHTML={{ __html: first.about }}></div>
            </div>
          </Slide>
        </div>
        <div className="owner">
          <Slide in="true" direction="right" timeout={4000}>
            <div className="left">
              <div>
                <h2>{second.name}</h2>
                <span>{second.cargo}</span>
              </div>
              <div dangerouslySetInnerHTML={{ __html: second.about }}></div>
            </div>
          </Slide>
          <Slide in="true" direction="left" timeout={4000}>
            <img className="clippatch" src={second.img} alt="" />
          </Slide>
        </div>
      </Content>
    );
  };

  const Employees = () => {
    return <Content className={fading[2]}>{ShowEmployees()}</Content>;
  };

  const ShowEmployees = () => {
    if (window.innerWidth > 789) {
      return (
        <Fade in="true" timeout={3000}>
          <div className="employees">
            <div className="unit">
              <img className="clippatch" src={Vinicius} alt="employee" />
              <p>VINICIUS FIOCCO DE OLIVEIRA</p>
              <span>Engenheiro Civil</span>
              <span>Universidade Presbiteriana Mackenzie</span>
            </div> 
            <div className="unit">
              <img className="clippatch" src={Jaqueline} alt="employee" />
              <p>JAQUELINE BORELLI DA SILVA</p>
              <span>Arquiteta e Urbanista</span>
              <span>UPF - Universidade de Passo Fundo</span>
            </div>
            <div className="unit">
              <img className="clippatch" src={Leandro} alt="employee" />
              <p>LEANDRO PUEL</p>
              <span>Engenheiro Civil</span>
              <span>UNIFEBE - Centro Universitário de Brusque</span>
            </div>
            <div className="unit">
              <img className="clippatch" src={Jordao} alt="employee" />
              <p>JORDÃO MÁRIO DE SANTANTA</p>
              <span>Engenheiro Mecânico</span>
              <span>UFSC - Universidade Federal de Santa Catarina</span>
            </div>  
            <div className="unit">
              <img className="clippatch" src={Charlene} alt="employee" />
              <p>CHARLENE MARA NISZ</p>
              <span>Despachante Imobiliário </span>
              <span>
                Técnica em Processos Documentais de Imóveis
              </span>
            </div>
            <div className="unit">
              <img className="clippatch" src={Gustavo} alt="employee" />
              <p>GUSTAVO DO PRADO UTZIG</p>
              <span>Engenheiro Civil</span>
              <span>
                UNIJUI - Universidade Regional do Noroeste do Estado do Rio
                Grande do Sul
              </span>
            </div>
            <div className="unit">
              <img className="clippatch" src={Everlice} alt="employee" />
              <p>EVERLICE PESCADOR</p>
              <span>Técnica em Contabilidade</span>
              <span>FAPAG - Faculdade Porto das Águas</span>
            </div>
             <div className="unit">
              <img className="clippatch" src={Elaine} alt="employee" />
              <p>ELAINE DE SOUZA H. DOS SANTOS</p>
              <span>Estudante de Engenharia Civil</span>
              <span>UNIAVAN-Centro Universitário Avantis</span>
            </div>
           <div className="unit">
                <img className="clippatch" src={Andressa} alt="employee" />
                <p>ANDRESSA DE OLIVEIRA TOLEDO</p>
                <span>Estudante de Arquitetura e Urbanismo</span>
                <span>UNIVALI - Universidade do Vale do Itajaí</span>
              </div>
              <div className="unit">
                <img className="clippatch" src={Veronica} alt="employee" />
                <p>VERÔNICA MENDONÇA FREITAS</p>
                <span>Estudante de Arquitetura e Urbanismo</span>
                <span>UNIVALI - Universidade do Vale do Itajaí</span>
              </div>
              <div className="unit">
                <img className="clippatch" src={Yasmin} alt="employee" />
                <p>YASMIM REGINA SOARES</p>
                <span>Auxiliar Administrativo</span>
              </div>
              <div className="unit"/>
          </div>
        </Fade>
      );
    } else {
      return (
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          <SwiperSlide>
            <div className="employees">
            <div className="unit">
              <img className="clippatch" src={Vinicius} alt="employee" />
              <p>VINICIUS FIOCCO DE OLIVEIRA</p>
              <span>Engenheiro Civil</span>
              <span>Universidade Presbiteriana Mackenzie</span>
            </div> 
            <div className="unit">
              <img className="clippatch" src={Jaqueline} alt="employee" />
              <p>JAQUELINE BORELLI DA SILVA</p>
              <span>Arquiteta e Urbanista</span>
              <span>UPF - Universidade de Passo Fundo</span>
            </div>
            <div className="unit">
              <img className="clippatch" src={Leandro} alt="employee" />
              <p>LEANDRO PUEL</p>
              <span>Engenheiro Civil</span>
              <span>UNIFEBE - Centro Universitário de Brusque</span>
            </div>
            <div className="unit">
              <img className="clippatch" src={Jordao} alt="employee" />
              <p>JORDÃO MÁRIO DE SANTANTA</p>
              <span>Engenheiro Mecânico</span>
              <span>UFSC - Universidade Federal de Santa Catarina</span>
            </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="employees">
              <div className="unit">
                <img className="clippatch" src={Charlene} alt="employee" />
                <p>CHARLENE MARA NISZ</p>
                <span>Despachante Imobiliário </span>
                <span>
                  Técnica em Processos Documentais de Imóveis
                </span>
              </div>
              <div className="unit">
                <img className="clippatch" src={Gustavo} alt="employee" />
                <p>GUSTAVO DO PRADO UTZIG</p>
                <span>Engenheiro Civil</span>
                <span>
                  UNIJUI - Universidade Regional do Noroeste do Estado do Rio
                  Grande do Sul
                </span>
              </div>
              <div className="unit">
                <img className="clippatch" src={Everlice} alt="employee" />
                <p>EVERLICE PESCADOR</p>
                <span>Técnica em Contabilidade</span>
                <span>FAPAG - Faculdade Porto das Águas</span>
              </div>
              <div className="unit">
                <img className="clippatch" src={Elaine} alt="employee" />
                <p>ELAINE DE SOUZA H. DOS SANTOS</p>
                <span>Estudante de Engenharia Civil</span>
                <span>UNIAVAN-Centro Universitário Avantis</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="employees">
            <div className="unit">
                <img className="clippatch" src={Andressa} alt="employee" />
                <p>ANDRESSA DE OLIVEIRA TOLEDO</p>
                <span>Estudante de Arquitetura e Urbanismo</span>
                <span>UNIVALI-Universidade do Vale do Itajaí</span>
              </div>
              <div className="unit">
                <img className="clippatch" src={Veronica} alt="employee" />
                <p>VERONICA MENDONÇA FREITAS</p>
                <span>Estudante de Arquitetura e Urbanismo</span>
                <span>UNIVALI - Universidade do Vale do Itajaí</span>
              </div>
              <div className="unit">
                <img className="clippatch" src={Yasmin} alt="employee" />
                <p>YASMIM REGINA SOARES</p>
                <span>Auxiliar Administrativo</span>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      );
    }
  };

  const RandomicOwners = () => {
    if (Math.random() < 0.5) {
      return Owners(rapha, nuno);
    } else {
      return Owners(nuno, rapha);
    }
  };

  const setContent = () => {
    switch (section) {
      case "company":
        return TheCompany();
      case "owners":
        return RandomicOwners();
      case "employees":
        return Employees();
      default:
        return TheCompany();
    }
  };

  return (
    <>
      <ScrollToTop/>
      {loading ? <AnimPage /> : <></>}
      <Header />
      <Wrap>
        <Slide in="true" direction="left" timeout={4000}>
          <Title className="alphaeffect">SOMOS INTEGRARE</Title>
        </Slide>
        <Submenu className="alphaeffect">
          <div
            onClick={() => handleSubmenuClick(0, "company")}
            className={submenuClass[0]}
          >
            <div>
              <span>EMPRESA</span>
            </div>
          </div>
          <div
            onClick={() => handleSubmenuClick(1, "owners")}
            className={submenuClass[1]}
          >
            <div>
              <span>SÓCIOS</span>
            </div>
          </div>
          <div
            onClick={() => handleSubmenuClick(2, "employees")}
            className={submenuClass[2]}
          >
            <div>
              <span>COLABORADORES</span>
            </div>
          </div>
        </Submenu>
        {setContent()}
        <Ancor />
      </Wrap>
      <Footer />
    </>
  );
};

export default Company;
