import React, { useState } from "react";
import { Wrap } from "./styles";
import A from "../../assets/effect/A.svg";
import E from "../../assets/effect/E.svg";
import G from "../../assets/effect/G.svg";
import I from "../../assets/effect/I.svg";
import N from "../../assets/effect/N.svg";
import R from "../../assets/effect/R.svg";
import T from "../../assets/effect/T.svg";

const AnimHome = () => {
  const [classe, setClasse] = useState("glowing");

  setTimeout(function () {
    if (classe === "glowing") {
      setClasse("active");
    }
  }, 2500);

  return (
    <Wrap>
      <section className={classe}>
        <div className="before">
          <img src={I} alt="n" />
        </div>
        <div className="n">
          <img src={N} alt="n" />
        </div>
        <div className="after">
          <img src={T} alt="t" />
          <img src={E} alt="e" />
          <img src={G} alt="g" />
          <img src={R} alt="r" />
          <img src={A} alt="a" />
          <img src={R} alt="r" />
          <img src={E} alt="e" />
        </div>
      </section>
    </Wrap>
  );
};

export default AnimHome;
