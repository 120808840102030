import React, { useState, useEffect } from "react";
import ScrollToTop from "../../components/scrolltop";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Wrap, Title, Submenu } from "../../style/styles";

import Clip from "../../assets/clip.svg";


import gifLoading from "../../assets/loading.gif";
import { Formstyle, Maps } from "./styles";
import ReactInputMask from "react-input-mask";
import { GetLocation } from "../../services/externalApiService";
import DropDownStates from "../../components/DropDownForm/states";
import DropDownCities from "../../components/DropDownForm/citys";
import Ancor from "../../components/ancor";
import AnimPage from "../../components/animPage";

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);

  //função do submenu pra escolher os formularios orçamento ou curriculo
  const [submenuClass, setSubmenuClass] = useState(["unit active", "unit"]);
  const [section, setSection] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [cpfCalc, setCpfCalc] = useState(false);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("Anexar Currículo");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [cep, setCep] = useState("");
  const [cpf, setCpf] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [rua, setRua] = useState("");
  const [mensagem, setMensagem] = useState("");

  useEffect(() => {
    setTimeout(function () {
      setLoading(false);
    }, 3200);
  }, [loading]);

  useEffect(() => {
    if (!cep.includes("_") && cep !== "") {
      fetchCEP(cep);
    }
  }, [cep]);

  useEffect(() => {
    if (!cpf.includes("_") && cpf !== "") {
      let t = cpf;
      t = t.replace(".", "");
      t = t.replace(".", "");
      t = t.replace("-", "");

      console.log(t)
      let isValid = isValidCPF(t);
      setCpfCalc(isValid);
    }
  }, [cpf]);

  function isValidCPF(cpf) {
    if (typeof cpf !== "string") return false;
    cpf = cpf.replace(/[\s.-]*/gim, "");
    if (
      !cpf ||
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    ) {
      return false;
    }
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (var n = 1; n <= 10; n++)
      soma = soma + parseInt(cpf.substring(n - 1, n)) * (12 - n);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;
    return true;
  }

  async function fetchCEP(temp) {
    let endereco = await GetLocation(temp);
    setEstado(endereco.state)
    setCidade(endereco.city)
    setBairro(endereco.district)
    setRua(endereco.address)
  }

  const handleFile = (e) => {
    setFileName(e.target.files[0].name);
    setFile({ selectedFile: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!cpfCalc) {
      alert("CPF inválido!");
      return;
    }

    const formData = new FormData();

    let route = "https://api-email.2bchosen.com.br/integrare-jobs";
    if(submenuClass[0] === "unit active") {
      route = "https://api-email.2bchosen.com.br/integrare"
    }

    console.log(route)
    formData.append("nome", name);
    formData.append("email", mail);
    formData.append("mensagem", mensagem);
    formData.append("cpf", cpf);
    formData.append("telefone", phone);
    formData.append("cep", cep);
    formData.append("bairro", bairro);
    formData.append("cidade", cidade);
    formData.append("estado", estado);
    formData.append("endereco", rua);

    if (file) {
      formData.append("anexo", file.selectedFile);
    }

    setSending(true);
    (async () => {
      const rawResponse = await fetch(
        route,
        {
          method: "POST",
          body: formData,
        }
      );
      const response = await rawResponse.status;
      if (response === 200) {
        reset();
        if (section === 0) {
          setSending(false);
          return alert("Enviado com Sucesso, logo entraremos em contato");
        }
        setSending(false);
        return (
          alert("Enviado com Sucesso, logo entraremos em contato"),
          setRedirect(true)
        );
      } else {
        setSending(false);
        alert("Ocorreu um erro, entre em contato mais tarde.");
      }
    })();
  };

  const reset = () => {
    setName("");
    setPhone("");
    setMail("");
    setCep("");
    setCpf("");
    setRua("");
    setBairro("");
    setEstado("");
    setCidade("");
    setMensagem("");
  };

  const handleSubmenuClick = (index) => {
    let items = [...submenuClass];
    for (let i = 0; i < items.length; ++i) {
      items[i] = "unit";
    }

    reset();

    items[index] = "unit active";
    setSubmenuClass(items);
    setSection(index);
  };

  return (
    <>
      <ScrollToTop/>
      {loading ? <AnimPage /> : <></>}
      {sending ? (
        <div id="sending">
          <img src={gifLoading} alt="loading" />
        </div>
      ) : (
        <></>
      )}
      {redirect ? (
        (window.location.href =
          "https://docs.google.com/forms/d/e/1FAIpQLScxLifLGxeawipR1be_Zon3Dr3W4dmEhLCoN1nCaCM034qMpw/viewform")
      ) : (
        <></>
      )}
      <Header />
      <Wrap>
        <Title>NÓS RESOLVEMOS</Title>
        <Submenu>
          <div
            onClick={() => handleSubmenuClick(0)}
            className={submenuClass[0]}
          >
            <div>
              <span>ORÇAMENTO</span>
            </div>
          </div>
          <div
            onClick={() => handleSubmenuClick(1)}
            className={submenuClass[1]}
          >
            <div>
              <span>TRABALHE CONOSCO</span>
            </div>
          </div>
        </Submenu>
        <Formstyle onSubmit={handleSubmit} className="alphaeffect">
          <input type="hidden" name="_subject" value="Pedido de Orçamento" />
          <div className="input double">
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nome*"
              required
            />
            <ReactInputMask
              type="tel"
              name="telefone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              mask="+99 (99)99999-9999"
              placeholder="Telefone*"
              required
            />
          </div>
          <div className="input double">
            <input
              type="email"
              name="email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              placeholder="Email*"
              required
            />
            <ReactInputMask
              name="cpf"
              mask="999.999.999-99"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              placeholder="CPF*"
              required
            />
          </div>
          <div className="input double">
            <ReactInputMask
              name="cep"
              mask="99999-999"
              placeholder="Cep*"
              value={cep}
              onChange={(e) => setCep(e.target.value)}
              required
            />
            <input
              type="text"
              name="district"
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
              placeholder="Bairro*"
            />
          </div>
          <div className="input double">
            <DropDownStates
              change={(e) => setEstado(e.target.value)}
              state={estado}
              valueActive={estado}
            />
            <DropDownCities
              change={(e) => setCidade(e.target.value)}
              state={estado}
              valueActive={cidade}
            />
          </div>
          <div className="input">
            <input
              type="text"
              name="address"
              value={rua}
              onChange={(e) => setRua(e.target.value)}
              placeholder="Endereço*"
            />
          </div>
          <div className="textarea">
            <textarea
              name="mensagem"
              placeholder="Mensagem:*"
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
              cols="30"
              rows="10"
              required
            ></textarea>
          </div>

          {section === 0 ? (
            <div>
              <button type="submit">Solicitar Orçamento</button>
            </div>
          ) : (
            <div className="flex-wrap">
              <div className="input file">
                <label htmlFor="arquivo">
                  <img alt="anexar curriculo" src={Clip} /> {fileName}
                </label>
                <input
                  id="arquivo"
                  type="file"
                  name="file"
                  onChange={(e) => handleFile(e)}
                />
              </div>
              <button type="submit">Quero me candidatar</button>
            </div>
          )}
        </Formstyle>
      </Wrap>
      <Maps>
        <h2 className="subtitle">ONDE ESTAMOS</h2>
        <iframe
          loading="lazy"
          allowFullScreen
          title="mapa"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCr3fxz2a-r8w015wTFynG3PuwKNXXjG0o&q=Integrare+Engenharia+%26+Arquitetura"
        ></iframe>
      </Maps>
      <Ancor />
      <Footer />
    </>
  );
};

export default Contact;
