import { useEffect, useState } from "react";
import { fetchCitys } from "../ibge";

const DropDownCitys = ({change = () => {},state, valueActive=''}) =>{
    const [cities, setCities] = useState ([]);

    useEffect(() =>{
        fetchCitys(state).then((cities) =>{
            setCities(cities);
        });
    }, [state]);

    return (
        <select id="city" name="city" onChange={change}>
            <option value="" selected>Cidade*</option>
            {cities.map((city)=>{
                const{id, nome} = city;
                if(nome === valueActive){
                    return(<option key={id} value={nome} selected>{nome}</option>);
                }else
                    return(<option key={id} value={nome}>{nome}</option>);
            })}
        </select>
    );
};

export default DropDownCitys;