import { useEffect, useState } from "react";
import { fetchStates } from "../ibge";

const DropDownStates = ({change = () => {}, valueActive = '' }) => {
    const [states, setStates] = useState([]);
    useEffect(()=>{
        fetchStates().then((states)=>{
            setStates(states);
        });
    }, []);

 return (
        <select id="state" name="state" onChange={change}>
            <option value="">Estado*</option>
            {states.map((state)=>{
                const{sigla, nome} = state;
                if(valueActive === sigla){
                    return (<option key={sigla} value={sigla} selected>{nome}</option>)
                } else {
                    return (<option key={sigla} value={sigla}>{nome}</option>)
                }
            })}
        </select>
    );
 };
export default DropDownStates;