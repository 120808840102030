import React, {useState} from "react";
import Logo from "../../assets/IntegrareEngenharia.svg";
import { Wrap, Menu } from "./styles"
import { Link } from "react-router-dom";

const Header = () => {
  const [active, setActive] = useState('');

  const handleMenuClick = () => {
    if(active === '') {
      setActive('open')
    } else {
      setActive('')
    }
  }

  return (
    <Wrap id="header">
      <Menu className={active}>
        <div onClick={handleMenuClick} className={`icon`}></div>
        <nav className="menu menu-mobile">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/empresa">A EMPRESA</Link></li>
            <li><Link to="/servicos">SERVIÇOS</Link></li>
            <li><Link to="/portfolio">PORTFÓLIO</Link></li>
            <li><Link to="/contato">CONTATO</Link></li>
          </ul>
        </nav>
      </Menu>
      <Link to="/"><img className={`logo ${active}IMG`} src={Logo} alt="Integrare Engenharia" /></Link>
      <div className="filler"></div>
    </Wrap>
  );
}

export default Header