import React, { useState, useEffect, useRef } from "react";
import ScrollToTop from "../../components/scrolltop";
import AnimPage from "../../components/animPage";
import { Link, useHistory } from "react-router-dom";
import { Wrap, Title, Subtitle } from "../../style/styles";
import {  Content, Unit, Filter, Empty } from "./styles";
import Header from "../../components/header";
import Footer from "../../components/footer";
import request from "./request.json";
import Todos from "../../assets/Todos_Prancheta_1.svg";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";
import Ancor from "../../components/ancor";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default function Portfolio() {
  const [itensCarousel, setItensCarousel] = useState(5);
  const [filter, setFilter] = useState('');
  const [developments, setDevelopments] = useState('');
  const [parceiros, setParceiros] = useState('');
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  const swiperRef = useRef(null);

  useEffect(() => {
    setTimeout(function() {
      setLoading(false)
    }, 3200);
  }, [loading]);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setItensCarousel(2);
    } else if (window.innerWidth < 750) {
      setItensCarousel(2);
    } else if (window.innerWidth < 1000) {
      setItensCarousel(3);
    }

    setParceiros(request.partners);
    setDevelopments(request.data);
  }, []);

  const handleFilter = (value) => {
    if(value === '') {
      setDevelopments(request.data)
      history.push(`/portfolio`);
      return
    }
    
    history.push(`/portfolio?partner=${value}`);
    setFilter(`?partner=${value}`)
    let filtered = request.data.filter(function (elem) {
      return elem.filter === value;
    });

    setDevelopments(filtered)
  };

  const makeUnit = (data, index) => {
    return (
      <Unit className="clippatch" key={index}>
        <Link className="overlay" to={`/portfolio/${data.slug}${filter}`}>
          <img className="cover clippatch" src={data.img} alt="" />
          <h3>
            {data.title}
            <span> {data.company}</span>
          </h3>
        </Link>
      </Unit>
    );
  };

  return (
    <>
      <ScrollToTop/>
      {loading ? <AnimPage /> : <></>}
      {/* <AnimPage /> */}
      <Header />
      <Wrap id="portfolio">
        <Title>NOSSO PORTFÓLIO</Title>
        <Subtitle>
          Esses são alguns dos projetos em que Nós Resolvemos!
        </Subtitle>
        <Content className="alphaeffect">
          <img src={Todos} onClick={() => handleFilter('')} alt="filtro todos os empreendimentos" className="img"></img>
          <Filter 
            onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
            onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
          >
            <Swiper
              ref={swiperRef}
              slidesPerView={ itensCarousel }
              navigation
              loop={true}
              autoplay={{ delay: 3000}}
              scrollbar={{ draggable: true }}
            >
              {parceiros ? parceiros.map(function(e, i) {
                return (
                  <SwiperSlide key={i}>
                    <img className="partner"
                      src={e.img}
                      onClick={() => handleFilter(e.filter)}
                      alt= {e.alt}
                    />
                  </SwiperSlide>
                )
              }) : ""}
            </Swiper>
          </Filter>

         
          {developments.length > 0 ? developments.map((c, i) => makeUnit(c, i)) : <Empty className="empy">Projetos não encontrados</Empty>}
        
          <Unit />

        </Content>
        <Ancor />
      </Wrap>
      <Footer />
    </>
  );
}
