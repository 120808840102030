import styled from "styled-components";

export const Wrap = styled.div`
  width: 90vw;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 30px 0;
  justify-content: space-between;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;

  .logo {
    width: 200px;
  }

  span {
    font-size: 12px
  }

  .info div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .info div img{
    margin-right: 15px;
  }

  .icon {
    width: 24px;

  }
  .icons {
    text-align: right;

    div {
      margin-bottom: 5px;
    }
    
    .icon {
      margin-left: 10px;
    }

    a {
      color: #666;
      text-decoration: none;
    }
  }

  @media(max-width: 950px) {
    flex-direction: column;

    .logo {
      margin-bottom: 60px;
    }
    /* .info div {
      justify-content: center;
    } */
    .icon{
      width: 24px;
      margin: 0px 0px 0px 10px
    }
    .icons {
      margin-top: 35px;
    }
  }
  
  @media(max-width: 490px) {
    width: 295px;
  }
`;
