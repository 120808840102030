import React from "react";
import { HashLink } from "react-router-hash-link";
import Arrow from "../../assets/arrowBack.svg";
import { Wrap } from "./styles"

const Ancor = () => {
  const scroll = () => {
    console.log('caiu')
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }

  return (
    <Wrap>
      <div onClick={() =>scroll()}>
        <img src={Arrow} alt="Voltar ao Topo" title="Voltar ao Topo" />
        <span>Topo</span>
      </div>
    </Wrap>
  )
}

export default Ancor