import React from "react";
import Banner1 from "../../assets/Banner/01-empreendimento-tittanium.webp";
import Banner2 from "../../assets/Banner/02-empreendimento-soltero.webp";
import Banner3 from "../../assets/Banner/03-empreendimento-pier-village.webp";
import Banner4 from "../../assets/Banner/04-rooftop-empreendimento-porto-santo.webp";
import BannerMobile1 from "../../assets/Banner/01-empreendimento-tittanium-mobile.webp";
import BannerMobile2 from "../../assets/Banner/02-empreendimento-soltero-mobile.webp";
import BannerMobile3 from "../../assets/Banner/03-empreendimento-pier-village-mobile.webp";
import BannerMobile4 from "../../assets/Banner/04-rooftop-empreendimento-porto-santo-mobile.webp";
import { Wrap } from "./styles"

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css';
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const Banner = () => {
  if (window.innerWidth > 789){
  return (
    <Wrap className="banner">
      <Swiper
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <div className="unit">
            <img src={Banner1} alt="Empreendimento Tittanium com foco na faixada" />
            <div className="title pleft">
              <h1>Engenharia e Arquitetura: unidas para projetar o futuro.</h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="unit">
            <img src={Banner2} alt="Empreendimento Soltero" />
            <div className="title pright">
              <h1>Inteligência através da integração.</h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="unit">
            <img src={Banner3} alt="Empreendimento Pier Village Residence com foco nas janelas" />
            <div className="title pright">
              <h1>Inovação, ética e atenção aos detalhes.</h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="unit">
            <img src={Banner4} alt="Empreendimento Porto Santo com foco na área de lazer com piscina" />
            <div className="pright">
              <h1 className="h1" >Viabilizando projetos <br/> desafiadores.</h1>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </Wrap>
  );
  }else{
    return (
      <Wrap className="banner">
        <Swiper
          slidesPerView={1}
          navigation
          autoplay={{ delay: 6000 }}
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <div className="unit">
              <img src={BannerMobile1} alt="Empreendimento Tittanium com foco na faixada" />
              <div className="title pleft">
                <h1>Engenharia e Arquitetura: unidas para projetar o futuro.</h1>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="unit">
              <img src={BannerMobile2} alt="Empreendimento Soltero" />
              <div className="title pleft">
                <h1>Inteligência através da integração.</h1>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="unit">
              <img src={BannerMobile3} alt="Empreendimento Pier Village Residence com foco nas janelas" />
              <div className="title pleft">
                <h1>Inovação, ética e atenção aos detalhes.</h1>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="unit">
              <img src={BannerMobile4} alt="Empreendimento Porto Santo com foco na área de lazer com piscina" />
              <div className="title pleft">
                <h1>Viabilizando projetos desafiadores.</h1>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </Wrap>
    );
  }
}

export default Banner