import React from "react";
import { Servicos, Title } from "./styles";
import BxArea from "../../assets/services-icons/bx-area.svg";
import Finance from "../../assets/services-icons/finance.svg";
import Plumber from "../../assets/services-icons/plumber.svg";
import Building from "../../assets/services-icons/building.svg";
import Architecture from "../../assets/services-icons/architecture.svg";
import Extinguisher from "../../assets/services-icons/extinguisher.svg";
import RealState from "../../assets/services-icons/real-estate-agent.svg";
import SolidBuilding from "../../assets/services-icons/solid_building.svg";
import Electrical from "../../assets/services-icons/electrical-services.svg";

const ServicesDone = (props) => {
  console.log(props.content);
  return (
    <Servicos className="alphaeffect">
      <Title>SERVIÇOS REALIZADOS NO PROJETO</Title>
      <div className="wrap">
        <div className="unit">
          {props.content.arquitetonico ? (
            <div>
              <img src={Architecture} alt="" />
              <p>PROJETO ARQUITETÔNICO</p>
            </div>
          ) : (
            <></>
          )}
          {props.content.estrutural ? (
            <div>
              <img src={Building} alt="" />
              <p>PROJETO ESTRUTURAL</p>
            </div>
          ) : (
            <></>
          )}
          {props.content.hidrossanitario ? (
            <div>
              <img src={Plumber} alt="" />
              <p>PROJETO HIDROSSANITÁRIO</p>
            </div>
          ) : (
            <></>
          )}
          {props.content.preventivo ? (
            <div>
              <img src={Extinguisher} alt="" />
              <p>PROJETO PREVENTIVO CONTRA INCÊNCIO</p>
            </div>
          ) : (
            <></>
          )}
          {props.content.eletrico ? (
            <div>
              <img src={Electrical} alt="" />
              <p>PROJETO ELÉTRICO E TELECOMUNICAÇÃO</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="unit">
          {props.content.retificacao ? (
            <div>
              <img src={Finance} alt="" />
              <p>VIABILIDADE FINANCEIRA E ECONÔMICA</p>
            </div>
          ) : (
            <></>
          )}
          {props.content.unificacao ? (
            <div>
              <img src={BxArea} alt="" />
              <p>REGULARIZAÇÃO DE TERRENOS</p>
            </div>
          ) : (
            <></>
          )}
          {props.content.incorporacao ? (
            <div>
              <img src={RealState} alt="" />
              <p>INCORPORAÇÃO IMOBILIÁRIA</p>
            </div>
          ) : (
            <></>
          )}
          {props.content.instituicao ? (
            <div>
              <img src={SolidBuilding} alt="" />
              <p>INSTITUIÇÃO DE CONDOMÍNIO</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Servicos>
  );
};

export default ServicesDone;
