import styled from "styled-components";

export const Empty = styled.div`
     font-size: 32px;
    text-align: center;
    display: block;
    font-family: 'Josefin Sans';
    margin: 32px 0px 32px 320px;
`;

export const Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
  margin-bottom: 40px;

  div {
    text-align: center;
    width: 100%;
  }

  .all{
    margin-top: 20px;
  }

  h3{
    cursor: pointer;
    font-family: "Josefin Sans", sans-serif;
    border-bottom: solid 1px #fff;
    width: auto;
    display: inline-block;
    margin: auto;
  }

  .partner {
    cursor: pointer;
    max-width: 140px;
    filter: grayscale(100%) brightness(800%);
    transition: all 0.3s;

    :hover {
      filter: none;
    }
  }

  img {
    margin: auto auto 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  .img{
    width: 70px;
    margin: 0 0 0 465px;
    padding:0 0 30px 0;
    cursor: pointer;
  }

  @media(max-width: 980px) {
    width: 90vw;
    margin: auto;

    .img{
      margin: 0 0 0 145px;
      align-self: center;
      cursor: pointer;
    }
  }
`;

export const Unit = styled.div`
  position: relative;
  width: 30%;
  margin: 0 16px 40px;
  height: auto;

  @media(max-width: 640px) {
     margin: 0 auto 40px;

  }
  @media(max-width: 980px) {
    width: 300px;
  }

  .cover {
    width: 100%;
  }

  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 90%;
    padding: 8px 0 10px;
    text-align: center;
    background-color: rgba(23,23,23, 0.85);
    font-family:"Josefin Sans", sans-serif;
    color: #ddd;
    font-weight: 600;
    font-size: 14px;

    span {
      display: block;
      margin-top: 8px;
      font-family: 'Josefin Sans', sans-serif;
      font-size: 16px;
      letter-spacing: 0.15px;
      font-weight: 300;
    }
  }

  .hover {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0%;
    height: 0%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.5s;
    background-color: rgba(127,127,127, 0.9);
    padding: 10px;
    box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden; 
    text-align: center;
    padding-top: 20px;
    clip-path: polygon(0 0, calc(100% - 40px) 0, 100% 40px, 100% 100%, 40px 100%, 0 calc(100% - 40px)); 

    img {
      margin: auto;
    }
    
    h4 {
      font-family:'Josefin Sans', sans-serif;
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 1px;
      margin: 0;
    }

    p {
      text-align: justify;
      font-family:'Josefin Sans', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
    
    button {
        :focus {outline:none;}
      ::-moz-focus-inner {border:0;}
      background-color: #F4F4F4;
      padding: 8px 60px;
      border: none;
      text-transform: uppercase;
      color: #7F7F7F;
      font-weight: 600;
      letter-spacing: .75px;
      cursor: pointer;
    }
  }
  &:hover{
    .hover{
      opacity: 1;
      width: 100%;
      height: 100%;
      p {
        opacity: 1;
      }
      h5 {
        opacity: 1;
      }
    }
  }
`;